import React, { useState } from 'react';
import {
  Col,
  Collapse,
  Container,
  Row,
} from 'react-bootstrap';
import { Drawer, } from 'react-bootstrap-drawer';

const ApplicationDrawer = (props: any) => {
  const [open, setOpen] = useState(false);

  const handleToggle = () => setOpen(!open);

  return (
    <Drawer {...props}>
      <Drawer.Toggle onClick={handleToggle} />

      <Collapse in={open}>
        <Drawer.Overflow>
          <Drawer.ToC>
            <Drawer.Header>「　Menu　」</Drawer.Header>
            <hr />

            <Drawer.Nav>
              <Drawer.Item href="/">Web3 Auth</Drawer.Item>
              <Drawer.Item href="/email-auth">Email Auth</Drawer.Item>
            </Drawer.Nav>
          </Drawer.ToC>
        </Drawer.Overflow>
      </Collapse>
    </Drawer>
  );
};

export const Application = (props: any) => {
  return (
    <Container fluid>
      <Row className="flex-xl-nowrap" style={{ margin: 0, padding: 0 }}>
        <Col as={ApplicationDrawer} xs={12} md={3} lg={2} />
        <Col xs={12} md={9} lg={10}>{props.children}</Col>
      </Row>
    </Container>
  );
};