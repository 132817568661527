import MetaMaskOnboarding from '@metamask/onboarding';
import React from 'react';
import { Alert, Form, FormLabel, ModalDialog } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';

import { WalletSignInStartRequest, WalletSignInStartResponse } from '@mosaic-markets/mosaic-api/auth_svc_pb';
import { WalletSignInFinalizeRequest, WalletSignInFinalizeResponse } from '@mosaic-markets/mosaic-api/auth_svc_pb';
import { ServiceError, AuthSvcClient } from '@mosaic-markets/mosaic-api/auth_svc_pb_service';
import { TypedDataUtils } from 'eth-sig-util';
import assert from 'assert';
import jwt from "jsonwebtoken";

export function WalletSignInButton() {
  const [signDisabled, setSignDisabled] = React.useState(true);
  const [accounts, setAccounts] = React.useState([]);
  const [output, setOutput] = React.useState('');
  const [verifyURL, setVerifyURL] = React.useState('');
  const onboarding = React.useRef<MetaMaskOnboarding>();

  React.useEffect(() => {
    if (!onboarding.current) {
      onboarding.current = new MetaMaskOnboarding();
    }
  }, []);

  React.useEffect(() => {
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      if (accounts.length > 0) {
        setSignDisabled(false);
      } else {
        setSignDisabled(true);
      }
    }
  }, [accounts]);

  React.useEffect(() => {
    function handleNewAccounts(newAccounts: any) {
      setAccounts(newAccounts);
    }

    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      window.ethereum
        .request({ method: 'eth_requestAccounts' })
        .then(handleNewAccounts);
      window.ethereum.on('accountsChanged', handleNewAccounts);
      return () => {
        window.ethereum.removeListener('accountsChanged', handleNewAccounts);
      };
    }
  }, []);

  const walletSign = async () => {
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      setOutput(await processWalletSignIn(accounts, setVerifyURL, setSignDisabled));
    } else {
      alert("MetaMask is not installed!");
    }
  };

  return (
    <div>
      <Button disabled={signDisabled} onClick={walletSign}>
        Click there to sign
      </Button>
      <div className='output'>
        <pre><code>{output}</code></pre>
      </div>
      {verifyURL ? <Button href={verifyURL} variant='success'>
        Verify Token 👉
      </Button> : null}
    </div>
  );
}

let processWalletSignIn = async function (accounts: any, setVerifyURL: any, setSignDisabled: any): Promise<string> {
  let output = '';

  // Step 1: query server for sign in flow start

  let authClient = new AuthSvcClient(process.env.REACT_APP_MOSAIC_E2E_GRPC_URL || 'https://api.mosaic.ms/grpc')

  let signInStartRequest = new WalletSignInStartRequest();
  signInStartRequest.setSchema('schema_v1');
  signInStartRequest.setAddress(accounts[0]);

  let signInResponse = await new Promise<WalletSignInStartResponse>((resolve, reject) => authClient.walletSignInStart(signInStartRequest,
    (err: ServiceError | null, resp: WalletSignInStartResponse | null) => {
      if (err) {
        reject(`gRPC Error ${err.code}: ${err.message}`);
        return;
      }

      resolve(resp as WalletSignInStartResponse);
    }
  )).catch((err: string) => {
    output += err + "\n";
  });

  if (signInResponse == undefined) {
    output += "No data to sign\n";
    return output;
  }

  // Step 2: ask to sign the typed data returned by the server

  let signInFinalizeRequest = new WalletSignInFinalizeRequest();
  signInFinalizeRequest.setSchema('schema_v1');
  signInFinalizeRequest.setDigest(signInResponse.getDigest());
  signInFinalizeRequest.setTimestamp(signInResponse.getTimestamp());

  try {
    const from = accounts[0];
    const signature = await window.ethereum.request({
      method: 'eth_signTypedData_v4',
      params: [from, signInResponse.getData()],
    });

    console.log("signing with", from);

    let sigHash = TypedDataUtils.sign(JSON.parse(signInResponse.getData()), true);
    console.log("got sigHash from typed data", signInResponse.getData(), sigHash.toString('hex'));

    output += `Signature: ${signature}\n`;
    signInFinalizeRequest.setSignature(signature)
  } catch (err) {
    console.error(err);
    output += `Error: ${(err as any).message}\n`;
    return output;
  }

  // Step 3: complete the sign-in flow and obtain a JWT token

  let jwtToken = await new Promise<string | undefined>((resolve, reject) => authClient.walletSignInFinalize(signInFinalizeRequest,
    (err: ServiceError | null, resp: WalletSignInFinalizeResponse | null) => {
      if (err) {
        reject(`gRPC Error ${err.code}: ${err.message}`);
        return;
      }

      resolve((resp as WalletSignInFinalizeResponse).getJwt());
    }
  )).catch((err: string) => {
    output += err + "\n";
  });

  if (jwtToken == undefined) {
    output += "No JWT token in response\n";
    return output;
  }

  const hashedToken: any = jwt.decode(jwtToken);
  localStorage.setItem("jwt_token", jwtToken);
  localStorage.setItem("user_id", hashedToken.user_id);

  output += "\nUser ID: \n" + hashedToken.user_id;
  output += "\n\nJWT token: " + jwtToken;
  setVerifyURL('https://jwt.io/#debugger-io?token=' + jwtToken);
  setSignDisabled(true);

  return output;
}
