import MetaMaskOnboarding from '@metamask/onboarding';
import React from 'react';
import { Alert, Form, FormLabel, ModalDialog } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';

import { EmailSignInStartRequest, EmailSignInStartResponse } from '@mosaic-markets/mosaic-api/auth_svc_pb';
import { EmailSignInFinalizeRequest, EmailSignInFinalizeResponse } from '@mosaic-markets/mosaic-api/auth_svc_pb';
import { ServiceError, AuthSvcClient } from '@mosaic-markets/mosaic-api/auth_svc_pb_service';
import { TypedDataUtils } from 'eth-sig-util';
import { IframeStamper } from "@turnkey/iframe-stamper";
import assert from 'assert';
import jwt from "jsonwebtoken";

export function EmailSignInButton() {
  const [signDisabled, setSignDisabled] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [output, setOutput] = React.useState('');
  const [verifyURL, setVerifyURL] = React.useState('');

  const iframeContainerId = "turnkey-iframe-container-id"
  const iframeElementId = "turnkey-iframe-element-id"

  const [iframeStamper, setIframeStamper] = React.useState({} as IframeStamper);

  React.useEffect(() => {
    let stamper: IframeStamper;

    try {
      stamper = new IframeStamper({
        iframeElementId: iframeElementId,
        iframeUrl: (process.env.REACT_APP_MOSAIC_E2E_TURNKEY_API || 'https://auth.turnkey.com'),
        iframeContainer: document.getElementById(iframeContainerId),
      });
    } catch (e: any) {
      console.log("error initing the stamper: ", e)
      return
    }

    setIframeStamper(stamper);
    stamper.init().then(async () => {
      // let's check for request URL query params

      const query = new URLSearchParams(window.location.search);

      const orgId = query.get('orgId');
      const bundle = query.get('bundle');

      if (orgId && orgId.length > 0) {
        if (bundle && bundle.length > 0) {
          console.log("Captured orgID:", orgId, "and Bundle:", bundle);

          // confirm session by exchanging bundle for an xstamp inside this callback
          setOutput(await finalizeEmailSignIn(orgId, bundle, setVerifyURL, setSignDisabled, stamper));
        }
      }
    });
  }, []);

  const sendEmail = async () => {
    setOutput(await processEmailSignIn(email, setSignDisabled, iframeStamper));
  };

  return (
    <div>
      <div id={iframeContainerId} style={{ display: 'none' }} />
      <Form>
        <Form.Group className="mb-3" controlId="email_address">
          <Form.Control size="lg" type="email" placeholder="user@mosaic-markets.com" value={email} onChange={(e) => setEmail(e.target.value)} />
        </Form.Group>
      </Form>
      <Button disabled={signDisabled || !(email && email.length > 0)} onClick={sendEmail}>
        Confirm email
      </Button>
      <div className='output'>
        <pre><code>{output}</code></pre>
      </div>
      {verifyURL ? <Button href={verifyURL} variant='success'>
        Verify Token 👉
      </Button> : null}
    </div>
  );
}

let processEmailSignIn = async function (email: string, setSignDisabled: any, iframeStamper: IframeStamper): Promise<string> {
  let output = '';

  // Step 1: query server for sign in flow start

  let authClient = new AuthSvcClient(process.env.REACT_APP_MOSAIC_E2E_GRPC_URL || 'https://api.mosaic.ms/grpc')

  let signInStartRequest = new EmailSignInStartRequest();
  signInStartRequest.setProvider('alchemy');
  signInStartRequest.setEmailAddress(email);
  const pubkey = iframeStamper.publicKey();
  signInStartRequest.setPubkey(pubkey ? '0x' + pubkey : '');

  let signInResponse = await new Promise<EmailSignInStartResponse>((resolve, reject) => authClient.emailSignInStart(signInStartRequest,
    (err: ServiceError | null, resp: EmailSignInStartResponse | null) => {
      if (err) {
        reject(`gRPC Error ${err.code}: ${err.message}`);
        return;
      }

      resolve(resp as EmailSignInStartResponse);
    }
  )).catch((err: string) => {
    output += err + "\n";
  });

  if (signInResponse == undefined) {
    output += "No response from the server\n";
    return output;
  } else if (signInResponse.getCooldown() > 0) {
    setSignDisabled(true);
    setTimeout(() => setSignDisabled(false), signInResponse.getCooldown());

    output += "\nPlease wait " + signInResponse.getCooldown() / 1000 + " seconds and try again";
    return output;
  }

  // Step 2: ask to check email
  setSignDisabled(true);
  output += "\n📧 Email sent! Now check your " + email + " and click the Sign In button";
  return output;
}

let finalizeEmailSignIn = async function (orgId: string, bundle: string, setVerifyURL: any, setSignDisabled: any, iframeStamper: IframeStamper): Promise<string> {
  let output = '';
  
  // Step 1: exchange bundle for an xstamp-ed request
  
  const injectedBundleResult = await iframeStamper.injectCredentialBundle(bundle);
  if (!injectedBundleResult) {
    output += "failed to inject credential bundle into iframeStamper\n";
    return output;
  }

  let stampedReq = await stampGetWhoami(iframeStamper, {
    organizationId: orgId,
  })

  // Step 2: construct signInFinalizeRequest

  let authClient = new AuthSvcClient(process.env.REACT_APP_MOSAIC_E2E_GRPC_URL || 'https://api.mosaic.ms/grpc')

  let signInFinalizeRequest = new EmailSignInFinalizeRequest();
  signInFinalizeRequest.setProvider('alchemy');
 
  const pubkey = iframeStamper.publicKey();
  signInFinalizeRequest.setPubkey(pubkey ? '0x' + pubkey : '');
  signInFinalizeRequest.setBundle(bundle);
  signInFinalizeRequest.setOrgId(orgId);
  signInFinalizeRequest.setXStamp(stampedReq.stamp.stampHeaderValue);

  console.log("sending", signInFinalizeRequest)

  // Step 3: complete the sign-in flow and obtain a JWT token

  let emailSignInFinalResponse = await new Promise<EmailSignInFinalizeResponse | undefined>((resolve, reject) => authClient.emailSignInFinalize(signInFinalizeRequest,
    (err: ServiceError | null, resp: EmailSignInFinalizeResponse | null) => {
      if (err) {
        reject(`gRPC Error ${err.code}: ${err.message}`);
        return;
      }

      resolve(resp as EmailSignInFinalizeResponse);
    }
  )).catch((err: string) => {
    output += err + "\n";
  });


  if (emailSignInFinalResponse == undefined) {
    output += "No response from the server\n";
    return output;
  } else if (emailSignInFinalResponse.getExpired()) {
    output += "Auth session has been expired, please start the new flow\n";
    return output;
  } else if (emailSignInFinalResponse.getJwt() == undefined) {
    output += "No JWT token in response\n";
    return output;
  } else if (output.length > 0) {
    return output;
  }

  const jwtToken = emailSignInFinalResponse.getJwt();

  const hashedToken: any = jwt.decode(jwtToken);
  localStorage.setItem("jwt_token", jwtToken);
  localStorage.setItem("user_id", hashedToken.user_id);
  localStorage.setItem("signer_address", hashedToken.signer_address);

  output += "\nUser ID: \n" + hashedToken.user_id;
  output += "\nSigner Address: \n" + hashedToken.signer_address;
  output += "\n\nJWT token: " + jwtToken;
  setVerifyURL('https://jwt.io/#debugger-io?token=' + jwtToken);
  setSignDisabled(true);

  return output;
}

type TGetWhoamiBody = {
  organizationId: string;
};

type TSignedRequest = {
  body: string;
  stamp: TStamp;
  url: string;
};

type TStamp = {
  stampHeaderName: string;
  stampHeaderValue: string;
};

let stampGetWhoami = async (stamper: IframeStamper, input: TGetWhoamiBody): Promise<TSignedRequest> => {
  const fullUrl = process.env.REACT_APP_MOSAIC_E2E_TURNKEY_API + "/public/v1/query/whoami";
  const body = JSON.stringify(input);
  const stamp = await stamper.stamp(body);
  return {
    body: body,
    stamp: stamp,
    url: fullUrl,
  };
};
