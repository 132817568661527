import React from "react";

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

import logo from "./logo.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-drawer/lib/style.css";
import "./App.css";
import { OnboardingButton } from "./components/OnboardingButton";
import { WalletSignInButton } from "./components/WalletSignInButton";
import { EmailSignInButton } from "./components/EmailSignInButton";
import { Application } from "./components/Application";

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <div className="App">
              <Application>
                <header className="App-header">
                  <h1>E2E Testpage for MosaicMarkets Backend</h1>
                  <OnboardingButton />
                  <br />

                  <h3>Wallet Sign-In</h3>
                  <WalletSignInButton />
                </header>
              </Application>
            </div>
          }
        />
        <Route
          path="/email-auth"
          element={
            <div className="App">
              <Application>
                <header className="App-header">
                  <h1>E2E Testpage for MosaicMarkets Backend</h1>
                  <h3>Email Sign-In</h3>
                  <EmailSignInButton />
                </header>
              </Application>
            </div>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
